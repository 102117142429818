import InnerLayout from "../components/InnerLayout";

const Partners = (props) => {
  return (
    <InnerLayout>
      <div className="row d-flex flex-row">
        <div className="col-sm-12 border" data-aos="zoom-in" data-aos-delay="200">
          <div className="section-title">
            <h2>Partners</h2>
          </div>

          <section id="why-us" className="why-us section-bg">
            <div className="row">

              <div className="col-lg-12 d-flex flex-column justify-content-center align-items-stretch  order-2 order-lg-1">

                <div className="accordion-list">
                  <ul>
                    <li>
                      <a data-toggle="collapse" className="collapse" href="#accordion-list-2024"><span>2024</span> Sponsors / Supporters <i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i></a>
                      <div id="accordion-list-2024" className="collapse show" data-parent=".accordion-list">
                        <div className="d-block">
                          <h3 className="text-primary text-center">Clearance by </h3>
                          <div className="row m-2">
                            <div className="col-md-4 text-center my-2">
                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="http://mha.nic.in/">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/gov-logo.jpg" />
                                  <div className=" text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary">Ministry of Home Affairs<br /> Government of India</p>
                                  </div>
                                </a>
                              </div>
                            </div>
                            <div className="col-md-4 text-center my-2">
                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="https://www.mea.gov.in/">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/gov-logo.jpg" />
                                  <div className="text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary">Ministry of External Affairs<br />Government of India</p>
                                  </div>
                                </a>
                              </div>
                            </div>
                            <div className="col-md-4 text-center my-2"><br /></div>
                          </div>

                          <h3 className="text-primary text-center">Sponsored by </h3>
                          <div className="row m-2">
                            <div className="col-md-4 text-center my-2"><br /></div>
                            <div className="col-md-4 text-center my-2">

                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="http://www.parsan.biz/">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/parsan.jpg" />
                                  <div className=" text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary">PARSAN Overseas Pvt. Ltd.</p>
                                  </div>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <hr />
                          <h3 className="text-primary text-center">Associates </h3>
                          <div className="row m-2">
                            <div className="col-md-4 text-center my-2">
                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="http://www.icid.org/">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/icid.png" />
                                  <div className="text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary">International Commission<br />on Irrigation and Drainage</p>
                                  </div>
                                </a>
                              </div>
                            </div>
                            <div className="col-md-4 text-center my-2">
                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="#">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/ddag.png" />
                                  <div className="text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary">Dr. Dhawan Academy of Geologists</p>
                                  </div>
                                </a>
                              </div>
                            </div>
                            <div className="col-md-4 text-center my-2">
                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="http://www.cidc.in/">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/cidc.jpg" />
                                  <div className=" text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary">Construction Industry<br />Development Council</p>
                                  </div>
                                </a>
                              </div>
                            </div>
                            <div className="col-md-4 text-center my-2">
                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="http://ee-foundation.org/">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/eee.png" />
                                  <div className=" text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary">Energy And Environment Foundation</p>
                                  </div>
                                </a>
                              </div>
                            </div>
                            <div className="col-md-4 text-center my-2">
                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="https://www.indianconcreteinstitute.org/">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/ici.png" />
                                  <div className="text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary">Indian Concrete<br/>Institute</p>
                                  </div>
                                </a>
                              </div>
                            </div>
                          </div>
                          <h3 className="text-primary text-center">Media Support by </h3>
                          <div className="row m-2">
                            <div className="col-md-4 text-center my-2">
                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="https://thewaterdigest.com/">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/water-digest-2024.png" />
                                  <div className=" text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary">Water Digest</p>
                                  </div>
                                </a>
                              </div>
                            </div>
                            <div className="col-md-4 text-center my-2">
                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="http://www.scorp-media.com/">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/infrastructure-line.png" />
                                  <div className=" text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary">Infrastructure Line</p>
                                  </div>
                                </a>
                              </div>
                            </div>
                            <div className="col-md-4 text-center my-2">
                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="https://spicos.com/">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/spicos.png" />
                                  <div className=" text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary">Spicos Media Communications</p>
                                  </div>
                                </a>
                              </div>
                            </div>
                            <div className="col-md-4 text-center my-2">
                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="https://thewaternetwork.com/">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/water-network-logo.png" />
                                  <div className=" text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary">The Water Network</p>
                                  </div>
                                </a>
                              </div>
                            </div>
                        </div>
                        <hr />
                        <h3 className="text-primary text-center">Technology Partner</h3>
                        <div className="row m-2">
                          <div className="col-md-4 text-center my-2"></div>
                          <div className="col-md-4 text-center my-2">

                            <div className="border p-0">
                              <a className="p-0" target="_blank" rel="noreferrer" href="https://hummz.com/">
                                <img alt="" className="m-2 py-4" style={{ height: "auto", width: "80%" }} src="assets/images/partners/hummz-logo.png" />
                                <div className=" text-white bg-primary w-100">
                                  <p className="p-1 text-white bg-primary">Hummz</p>
                                </div>
                              </a>
                            </div>
                          </div>
                          <div className="col-md-4 text-center my-2"></div>
                        </div>
                        <hr />
                      </div>
                    </li>
                    <li>
                      <a data-toggle="collapse" className="collapse" href="#accordion-list-2021"><span>2021</span> Sponsors / Supporters <i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i></a>
                      <div id="accordion-list-2021" className="collapse show" data-parent=".accordion-list">
                        <div className="d-block">
                          <h3 className="text-primary text-center">Sponsored by </h3>
                          <div className="row m-2">
                            <div className="col-md-4 text-center my-2"><br /></div>
                            <div className="col-md-4 text-center my-2">

                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="http://www.parsan.biz/">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/parsan.jpg" />
                                  <div className=" text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary">PARSAN Overseas Pvt. Ltd.</p>
                                  </div>
                                </a>
                              </div>
                            </div>

                          </div>

                          <hr />
                          <h3 className="text-primary text-center">Associates </h3>
                          <div className="row m-2">
                            <div className="col-md-4 text-center my-2">

                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="http://www.icid.org/">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/icid.png" />
                                  <div className="text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary">International Commission<br />on Irrigation and Drainage</p>
                                  </div>
                                </a>
                              </div>
                            </div>

                            <div className="col-md-4 text-center my-2">

                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="http://www.cidc.in/">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/cidc.jpg" />
                                  <div className=" text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary">Construction Industry<br />Development Council</p>
                                  </div>
                                </a>
                              </div>
                            </div>

                            <div className="col-md-4 text-center my-2">

                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="https://www.ecindia.org/">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/Eci-logo.jpg" />
                                  <div className="text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary"> Engineering Council <br />of India</p>
                                  </div>
                                </a>
                              </div>
                            </div>

                          </div>





                          <hr />
                          <h3 className="text-primary text-center">Exhibitors </h3>
                          <h3 className="text-primary ml-4">Gold </h3>
                          <div className="row m-2">
                            <div className="col-md-4 text-center my-2">

                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="https://www.ambrogeo.eu/">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/ambrgeo.jpg" />
                                  <div className=" text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary">Ambrogeo</p>
                                  </div>
                                </a>
                              </div>
                            </div>

                            <div className="col-md-4 text-center my-2">

                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="http://www.guidelinegeo.com/">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/abem.jpg" />
                                  <div className="text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary">Guideline Geo AB</p>
                                  </div>
                                </a>
                              </div>
                            </div>

                            <div className="col-md-4 text-center my-2">

                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="http://www.electrosteel.com/">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/electrosteel.jpg" />
                                  <div className="text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary">Electrosteel Castings Limited</p>
                                  </div>
                                </a>
                              </div>
                            </div>

                          </div>

                          <h3 className="text-primary ml-4">Silver </h3>
                          <div className="row m-2">
                            <div className="col-md-4 text-center my-2">

                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="https://timetechnoplast.com/">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/timetechnoplast.jpg" />
                                  <div className=" text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary">Time Technoplast Ltd</p>
                                  </div>
                                </a>
                              </div>
                            </div>

                            <div className="col-md-4 text-center my-2">
                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="http://www.jains.com/">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/jain.jpg" />
                                  <div className="text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary">Jain Irrigation Systems Ltd</p>
                                  </div>
                                </a>
                              </div>
                            </div>
                            {/*<div className="col-md-4 text-center my-2">
                            <div className="border p-0">
                            <a className="p-0" target="_blank" rel="noreferrer" href="https://litermeter.in/">
                              <img alt="" className="m-2" style={{height:"90px", width:"auto" }}  src="assets/images/partners/liter.jpg"/>
                                <div className="text-white bg-primary w-100">
                                  <p className="p-1 text-white bg-primary">Litermeter</p>
                                </div>
                              </a>
                              </div>
                          </div>*/}
                            <div className="col-md-4 text-center my-2">
                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="http://geovale.com/">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/geovale.jpg" />
                                  <div className="text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary">Geovale Services Pvt Ltd.</p>
                                  </div>
                                </a>
                              </div>
                            </div>
                            <div className="col-md-4 text-center my-2">
                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="http://www.instrumentation-solutions.com/">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/instrument.jpg" />
                                  <div className="text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary">Complete Instrumentation<br /> Solutions Pvt. Ltd</p>
                                  </div>
                                </a>
                              </div>
                            </div>

                          </div>

                          <h3 className="text-primary ml-4">Bronze </h3>

                          <div className="row m-2">
                            {/*<div className="col-md-4 text-center my-2">
                            
                            <div className="border p-0">
                            <a className="p-0" target="_blank" rel="noreferrer" href="https://mugammaconsultants.com/">
                              <img alt="" className="m-2" style={{height:"90px", width:"auto" }} src="assets/images/partners/mugamma.jpg"/>
                                <div className=" text-white bg-primary w-100">
                                  <p className="p-1 text-white bg-primary">Mu Gamma Consultants Pvt Ltd. </p>
                                </div>
                              </a>
                              </div>
                          </div>
                          
                          <div className="col-md-4 text-center my-2">
                            <div className="border p-0">
                            <a className="p-0" target="_blank" rel="noreferrer" href="http://geovale.com/">
                              <img alt="" className="m-2" style={{height:"90px", width:"auto" }}  src="assets/images/partners/geovale.jpg"/>
                                <div className="text-white bg-primary w-100">
                                  <p className="p-1 text-white bg-primary">Geovale Services Pvt Ltd.</p>
                                </div>
                              </a>
                              </div>
                          </div>
                          <div className="col-md-4 text-center my-2">
                            <div className="border p-0">
                            <a className="p-0" target="_blank" rel="noreferrer" href="http://genesisenviroman.com/">
                              <img alt="" className="m-2" style={{height:"90px", width:"auto" }}  src="assets/images/partners/genesis.jpg"/>
                                <div className="text-white bg-primary w-100">
                                  <p className="p-1 text-white bg-primary">Genesis Enviroman Pvt. Ltd.</p>
                                </div>
                              </a>
                              </div>
                          </div>*/}
                            <div className="col-md-4 text-center my-2">
                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="https://www.geomative.com/">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/geomative1.jpg" />
                                  <div className="text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary">St. Geomative Co. Ltd.</p>
                                  </div>
                                </a>
                              </div>
                            </div>
                            <div className="col-md-4 text-center my-2">
                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="http://www.aquality.in">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/aquality.jpg" />
                                  <div className="text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary">AQUALITY Water Solutions Pvt. Ltd </p>
                                  </div>
                                </a>
                              </div>
                            </div>
                            <div className="col-md-4 text-center my-2">
                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="https://www.waecorp.com">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/wae.jpg" />
                                  <div className="text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary">WAE LIMITED</p>
                                  </div>
                                </a>
                              </div>
                            </div>
                            <div className="col-md-4 text-center my-2">
                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="https://www.rivulis.com">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/rivulus.jpg" />
                                  <div className="text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary">Rivulis Irrigation India Pvt Ltd.</p>
                                  </div>
                                </a>
                              </div>
                            </div>


                          </div>


                        </div>


                        <hr />
                        <h3 className="text-primary text-center">Industry Support </h3>


                        <div className="row m-2">
                          <div className="col-md-4 text-center my-2">

                            <div className="border p-0">
                              <a className="p-0" target="_blank" rel="noreferrer" href="https://dynasoure.com/">
                                <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/dynasource.png" />
                                <div className=" text-white bg-primary w-100">
                                  <p className="p-1 text-white bg-primary">Dynasoure Concrete Treatment Pvt Ltd</p>
                                </div>
                              </a>
                            </div>
                          </div>

                          <div className="col-md-4 text-center my-2">

                            <div className="border p-0">
                              <a className="p-0" target="_blank" rel="noreferrer" href="https://multiplexgroup.com/">
                                <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/multiplexbiotech.png" />
                                <div className="text-white bg-primary w-100">
                                  <p className="p-1 text-white bg-primary">Multiplex Bio-tech Pvt Ltd</p>
                                </div>
                              </a>
                            </div>
                          </div>

                          <div className="col-md-4 text-center my-2">

                            <div className="border p-0">
                              <a className="p-0" target="_blank" rel="noreferrer" href="https://www.planystech.com//">
                                <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/planys.png" />
                                <div className="text-white bg-primary w-100">
                                  <p className="p-1 text-white bg-primary">Planys Technologies</p>
                                </div>
                              </a>
                            </div>
                          </div>

                          <div className="col-md-4 text-center my-2">

                            <div className="border p-0">
                              <a className="p-0" target="_blank" rel="noreferrer" href="https://www.sika.com/">
                                <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/sika.png" />
                                <div className="text-white bg-primary w-100">
                                  <p className="p-1 text-white bg-primary">Sika AG</p>
                                </div>
                              </a>
                            </div>
                          </div>

                        </div>



                        <h3 className="text-primary text-center">Media Support by </h3>
                        <div className="row m-2">
                          <div className="col-md-4 text-center my-2">
                            <div className="border p-0">
                              <a className="p-0" target="_blank" rel="noreferrer" href="http://www.smartwww.in/">
                                <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/express.jpg" />
                                <div className=" text-white bg-primary w-100">
                                  <p className="p-1 text-white bg-primary">Smart Water & Waste World</p>
                                </div>
                              </a>
                            </div>
                          </div>

                          <div className="col-md-4 text-center my-2">
                            <div className="border p-0">
                              <a className="p-0" target="_blank" rel="noreferrer" href="https://thewaterdigest.com/">
                                <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/WaterDigest.png" />
                                <div className=" text-white bg-primary w-100">
                                  <p className="p-1 text-white bg-primary">Water Digest</p>
                                </div>
                              </a>
                            </div>
                          </div>

                          <div className="col-md-4 text-center my-2">
                            <div className="border p-0">
                              <a className="p-0" target="_blank" rel="noreferrer" href="http://www.eawater.com">
                                <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/eaw.jpg" />
                                <div className=" text-white bg-primary w-100">
                                  <p className="p-1 text-white bg-primary">Everything About Water</p>
                                </div>
                              </a>
                            </div>
                          </div>


                          <div className="col-md-4 text-center my-2">
                            <div className="border p-0">
                              <a className="p-0" target="_blank" rel="noreferrer" href="https://www.indiawaterportal.org/">
                                <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/india-water-logo.png" />
                                <div className=" text-white bg-primary w-100">
                                  <p className="p-1 text-white bg-primary">India Water Portal</p>
                                </div>
                              </a>
                            </div>
                          </div>

                          <div className="col-md-4 text-center my-2">
                            <div className="border p-0">
                              <a className="p-0" target="_blank" rel="noreferrer" href="https://hindi.indiawaterportal.org/">
                                <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/india-water-logo-hindi.png" />
                                <div className=" text-white bg-primary w-100">
                                  <p className="p-1 text-white bg-primary">Hindi India Water Portal</p>
                                </div>
                              </a>
                            </div>
                          </div>
                          <div className="col-md-4 text-center my-2">
                            <div className="border p-0">
                              <a className="p-0" target="_blank" rel="noreferrer" href="https://www.globalwaterintel.com/">
                                <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/gwi.jpg" />
                                <div className=" text-white bg-primary w-100">
                                  <p className="p-1 text-white bg-primary">Global Water Intelligence</p>
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                        <hr />
                        <h3 className="text-primary text-center">Technology Partner</h3>
                        <div className="row m-2">
                          <div className="col-md-4 text-center my-2"></div>
                          <div className="col-md-4 text-center my-2">

                            <div className="border p-0">
                              <a className="p-0" target="_blank" rel="noreferrer" href="https://hummz.com/">
                                <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/hummz.jpg" />
                                <div className=" text-white bg-primary w-100">
                                  <p className="p-1 text-white bg-primary">Hummz</p>
                                </div>
                              </a>
                            </div>
                          </div>
                          <div className="col-md-4 text-center my-2"></div>
                        </div>
                        <hr />
                      </div>
                    </li>
                    <li>
                      <a data-toggle="collapse" className="collapsed" href="#accordion-list-1"><span>2020</span> Sponsors / Supporters <i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i></a>
                      <div id="accordion-list-1" className="collapse" data-parent=".accordion-list">
                        <div className="d-block">
                          <div className="row m-2">
                            <div className="col-md-4 text-center my-2">
                              <h3 className="text-primary">Sponsored by </h3>
                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="http://www.parsan.biz/">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/parsan.jpg" />
                                  <div className=" text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary">PARSAN Overseas Pvt. Ltd.</p>
                                  </div>
                                </a>
                              </div>
                            </div>

                            <div className="col-md-4 text-center my-2">
                              <h3 className="text-primary">Theme Sponsor</h3>
                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="https://www.rmsi.com/">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/rmsi.jpg" />
                                  <div className="text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary">RMSI Pvt. Ltd.</p>
                                  </div>
                                </a>
                              </div>
                            </div>
                            <div className="col-md-4 text-center my-2">
                              <h3 className="text-primary">Industry Presenter </h3>
                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="https://nualgi.com/">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/nualgi.jpg" />
                                  <div className="text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary">Nualgi</p>
                                  </div>
                                </a>
                              </div>
                            </div>
                          </div>

                          <hr />
                          <h3 className="text-primary text-center">Associates </h3>
                          <div className="row m-2">
                            <div className="col-md-4 text-center my-2">

                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="http://www.cidc.in/">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/cidc.jpg" />
                                  <div className=" text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary">Construction Industry<br />Development Council</p>
                                  </div>
                                </a>
                              </div>
                            </div>

                            <div className="col-md-4 text-center my-2">

                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="https://www.ecindia.org/">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/Eci-logo.jpg" />
                                  <div className="text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary"> Engineering Council <br />of India</p>
                                  </div>
                                </a>
                              </div>
                            </div>

                            <div className="col-md-4 text-center my-2">

                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="http://www.icid.org/">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/icid.png" />
                                  <div className="text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary">International Commission<br />on Irrigation and Drainage</p>
                                  </div>
                                </a>
                              </div>
                            </div>
                            <div className="col-md-4 text-center my-2">

                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="http://www.cbip.org/">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/cbip.jpg" />
                                  <div className="text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary">Central Board of<br />Irrigation and Power</p>
                                  </div>
                                </a>
                              </div>
                            </div>
                          </div>

                          <hr />
                          <h3 className="text-primary text-center">Exhibitors </h3>
                          <h3 className="text-primary ml-4">Gold </h3>
                          <div className="row m-2">
                            <div className="col-md-4 text-center my-2">

                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="https://www.ambrogeo.eu/">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/ambrgeo.jpg" />
                                  <div className=" text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary">Ambrogeo</p>
                                  </div>
                                </a>
                              </div>
                            </div>

                            <div className="col-md-4 text-center my-2">

                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="http://www.guidelinegeo.com/">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/abem.jpg" />
                                  <div className="text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary">Guideline Geo AB</p>
                                  </div>
                                </a>
                              </div>
                            </div>

                          </div>

                          <h3 className="text-primary ml-4">Silver </h3>
                          <div className="row m-2">
                            <div className="col-md-4 text-center my-2">

                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="https://quagroup.com/">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/qua.jpg" />
                                  <div className=" text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary">QUA</p>
                                  </div>
                                </a>
                              </div>
                            </div>

                            <div className="col-md-4 text-center my-2">
                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="http://www.jains.com/">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/jain.jpg" />
                                  <div className="text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary">Jain Irrigation Systems Ltd</p>
                                  </div>
                                </a>
                              </div>
                            </div>
                            <div className="col-md-4 text-center my-2">
                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="https://litermeter.in/">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/liter.jpg" />
                                  <div className="text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary">Litermeter</p>
                                  </div>
                                </a>
                              </div>
                            </div>
                            <div className="col-md-4 text-center my-2">
                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="http://www.instrumentation-solutions.com/">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/instrument.jpg" />
                                  <div className="text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary">Complete Instrumentation<br /> Solutions Pvt. Ltd</p>
                                  </div>
                                </a>
                              </div>
                            </div>
                          </div>

                          <h3 className="text-primary ml-4">Bronze </h3>
                          <div className="row m-2">
                            <div className="col-md-4 text-center my-2">

                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="https://mugammaconsultants.com/">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/mugamma.jpg" />
                                  <div className=" text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary">Mu Gamma Consultants Pvt Ltd. </p>
                                  </div>
                                </a>
                              </div>
                            </div>

                            <div className="col-md-4 text-center my-2">
                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="http://geovale.com/">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/geovale.jpg" />
                                  <div className="text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary">Geovale Services Pvt Ltd.</p>
                                  </div>
                                </a>
                              </div>
                            </div>
                            <div className="col-md-4 text-center my-2">
                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="http://genesisenviroman.com/">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/genesis.jpg" />
                                  <div className="text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary">Genesis Enviroman Pvt. Ltd.</p>
                                  </div>
                                </a>
                              </div>
                            </div>
                            <div className="col-md-4 text-center my-2">
                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="https://www.geomative.com/">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/geomative.jpg" />
                                  <div className="text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary">St. Geomative Co. Ltd.</p>
                                  </div>
                                </a>
                              </div>
                            </div>
                            <div className="col-md-4 text-center my-2">
                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="https://www.youtube.com/watch?v=1_0ovAAAzOM">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/benix.jpg" />
                                  <div className="text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary">ISP Advanced Electronics </p>
                                  </div>
                                </a>
                              </div>
                            </div>
                            <div className="col-md-4 text-center my-2">
                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="http://www.mtsengrs.com/">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/mts.jpg" />
                                  <div className="text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary">MTS Engineers Pvt. Ltd. </p>
                                  </div>
                                </a>
                              </div>
                            </div>
                            <div className="col-md-4 text-center my-2">
                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="https://www.carpitech.com/en/home/">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/carpi.jpg" />
                                  <div className="text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary">CARPI Waterproofing<br /> Waterproofing Specialists Pvt. Ltd.</p>
                                  </div>
                                </a>
                              </div>
                            </div>


                          </div>


                        </div>
                        <hr />
                        <h3 className="text-primary text-center">Technology Partner</h3>
                        <div className="row m-2">
                          <div className="col-md-4 text-center my-2">

                            <div className="border p-0">
                              <a className="p-0" target="_blank" rel="noreferrer" href="https://hummz.com/">
                                <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/hummz.jpg" />
                                <div className=" text-white bg-primary w-100">
                                  <p className="p-1 text-white bg-primary">Hummz</p>
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>

                        <hr />
                        <h3 className="text-primary text-center">Media Support by </h3>
                        <div className="row m-2">
                          <div className="col-md-4 text-center my-2">
                            <div className="border p-0">
                              <a className="p-0" target="_blank" rel="noreferrer" href="https://www.indiawaterportal.org/">
                                <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/india-water-logo.png" />
                                <div className=" text-white bg-primary w-100">
                                  <p className="p-1 text-white bg-primary">India Water Portal</p>
                                </div>
                              </a>
                            </div>
                          </div>

                          <div className="col-md-4 text-center my-2">
                            <div className="border p-0">
                              <a className="p-0" target="_blank" rel="noreferrer" href="https://hindi.indiawaterportal.org/">
                                <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/india-water-logo-hindi.png" />
                                <div className=" text-white bg-primary w-100">
                                  <p className="p-1 text-white bg-primary">Hindi India Water Portal</p>
                                </div>
                              </a>
                            </div>
                          </div>

                          <div className="col-md-4 text-center my-2">
                            <div className="border p-0">
                              <a className="p-0" target="_blank" rel="noreferrer" href="http://www.smartwww.in/">
                                <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/express.jpg" />
                                <div className=" text-white bg-primary w-100">
                                  <p className="p-1 text-white bg-primary">Smart Water & Waste World</p>
                                </div>
                              </a>
                            </div>
                          </div>

                          <div className="col-md-4 text-center my-2">
                            <div className="border p-0">
                              <a className="p-0" target="_blank" rel="noreferrer" href="http://www.eawater.com">
                                <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/eaw.jpg" />
                                <div className=" text-white bg-primary w-100">
                                  <p className="p-1 text-white bg-primary">Everything About Water</p>
                                </div>
                              </a>
                            </div>
                          </div>

                          <div className="col-md-4 text-center my-2">
                            <div className="border p-0">
                              <a className="p-0" target="_blank" rel="noreferrer" href="https://www.globalwaterintel.com/">
                                <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/gwi.jpg" />
                                <div className=" text-white bg-primary w-100">
                                  <p className="p-1 text-white bg-primary">Global Water Intelligence</p>
                                </div>
                              </a>
                            </div>
                          </div>

                          <div className="col-md-4 text-center my-2">
                            <div className="border p-0">
                              <a className="p-0" target="_blank" rel="noreferrer" href="http://www.aiaiindia.com/">
                                <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/aia-2017.png" />
                                <div className=" text-white bg-primary w-100">
                                  <p className="p-1 text-white bg-primary">AIAI</p>
                                </div>
                              </a>
                            </div>
                          </div>

                          <div className="col-md-6 text-center my-2">
                            <div className="border p-0">
                              <a className="p-0" target="_blank" rel="noreferrer" href="https://thewaterdigest.com/">
                                <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/water-news.jpg" />
                                <div className=" text-white bg-primary w-100">
                                  <p className="p-1 text-white bg-primary">Water Digest</p>
                                </div>
                              </a>
                            </div>
                          </div>

                          <div className="col-md-6 text-center my-2">
                            <div className="border p-0">
                              <a className="p-0" target="_blank" rel="noreferrer" href="http://eawater.com/virtual_expo/">
                                <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/virtual-expo.png" />
                                <div className=" text-white bg-primary w-100">
                                  <p className="p-1 text-white bg-primary">Everything About Water - Virtual Expo</p>
                                </div>
                              </a>
                            </div>
                          </div>

                        </div>

                        <hr />


                      </div>
                    </li>
                    <li>
                      <a data-toggle="collapse" href="#accordion-list-2" className="collapsed"><span>2019</span> Sponsors / Supporters <i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i></a>
                      <div id="accordion-list-2" className="collapse" data-parent=".accordion-list">
                        <div className="d-block">
                          <h3 className="text-primary text-center">Clearance by </h3>
                          <div className="row m-2">
                            <div className="col-md-4 text-center my-2">

                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="http://mha.nic.in/">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/gov-logo.jpg" />
                                  <div className=" text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary">Ministry of Home Affairs<br /> Government of India</p>
                                  </div>
                                </a>
                              </div>
                            </div>

                            <div className="col-md-4 text-center my-2">
                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="https://www.mea.gov.in/">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/gov-logo.jpg" />
                                  <div className="text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary">Ministry of External Affairs<br />Government of India</p>
                                  </div>
                                </a>
                              </div>
                            </div>
                            <div className="col-md-8 text-center my-2">
                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="http://mowr.gov.in/">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/gov-logo.jpg" />
                                  <div className="text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary">Ministry of Jal Shakti<br />Department of Water Resources,<br />River Development & Ganga Rejuvenation </p>
                                  </div>
                                </a>
                              </div>
                            </div>
                          </div>

                          <hr />
                          <h3 className="text-primary text-center">Sponsored by </h3>
                          <div className="row m-2">
                            <div className="col-md-4 text-center my-2">

                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="http://www.parsan.biz/">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/parsan.jpg" />
                                  <div className=" text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary">PARSAN Overseas Pvt. Ltd.</p>
                                  </div>
                                </a>
                              </div>
                            </div>

                            <div className="col-md-4 text-center my-2">

                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="https://www.electrosteel.com/">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/elctro-steel.jpg" />
                                  <div className="text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary">Electrosteel Castings</p>
                                  </div>
                                </a>
                              </div>
                            </div>
                            <div className="col-md-4 text-center my-2">

                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="https://www.nabard.org/default.aspx">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/logo-nabard.jpg" />
                                  <div className="text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary">NABARD</p>
                                  </div>
                                </a>
                              </div>
                            </div>

                            <div className="col-md-4 text-center my-2">

                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="https://moes.gov.in/">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/gov-logo.jpg" />
                                  <div className="text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary">Ministry of Earth Sciences<br />Government of India</p>
                                  </div>
                                </a>
                              </div>
                            </div>
                            <div className="col-md-4 text-center my-2">

                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="http://geovale.com/">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/geovale.jpg" />
                                  <div className="text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary">Central Board of<br />Geovale Services</p>
                                  </div>
                                </a>
                              </div>
                            </div>
                          </div>

                          <hr />
                          <h3 className="text-primary text-center">Associates </h3>
                          <div className="row m-2">
                            <div className="col-md-4 text-center my-2">

                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="http://nwm.gov.in/">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/nwm.jpg" />
                                  <div className=" text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary">National Water Mission <br />Govt. of India <br />Ministry of Jal Shakti</p>
                                  </div>
                                </a>
                              </div>
                            </div>

                            <div className="col-md-4 text-center my-2">
                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="http://www.cwc.gov.in/">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/cwc-logo2.jpg" />
                                  <div className="text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary">Central Water Commission <br />Govt. of India <br />Ministry of Jal Shakti</p>
                                  </div>
                                </a>
                              </div>
                            </div>

                            <div className="col-md-4 text-center my-2">

                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="https://www.phdcci.in/about-us/phd-foundation/phd-rural-development-foundation/">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/phd.jpg" />
                                  <div className="text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary">PHD Rural <br />Development Foundation<br />A social arm of <br />PHD Chamber of Commerce <br />and Industry</p>
                                  </div>
                                </a>
                              </div>
                            </div>

                            <div className="col-md-4 text-center my-2">
                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="http://www.cbip.org/">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/cbip.jpg" />
                                  <div className="text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary">Central Board of<br /> Irrigation and Power</p>
                                  </div>
                                </a>
                              </div>
                            </div>

                            <div className="col-md-4 text-center my-2">
                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="http://www.icid.org/">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/icid.png" />
                                  <div className="text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary">International Commission<br />on Irrigation and Drainage</p>
                                  </div>
                                </a>
                              </div>
                            </div>

                            <div className="col-md-4 text-center my-2">
                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="http://www.cidc.in/">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/cidc.jpg" />
                                  <div className="text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary">Construction Industry<br />Development Council</p>
                                  </div>
                                </a>
                              </div>
                            </div>

                            <div className="col-md-4 text-center my-2">
                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="http://ee-foundation.org/">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/eef.jpg" />
                                  <div className="text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary"> Energy And Environment<br />Foundation</p>
                                  </div>
                                </a>
                              </div>
                            </div>

                          </div>

                          <h3 className="text-primary text-center">Media Support by</h3>
                          <div className="row m-2">
                            <div className="col-md-4 text-center my-2">

                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="https://asianngo.org/">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/AsianNGO.jpg" />
                                  <div className=" text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary">Asian NGO</p>
                                  </div>
                                </a>
                              </div>
                            </div>

                            <div className="col-md-4 text-center my-2">
                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="https://www.globalwaterintel.com/">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/gwi.jpg" />
                                  <div className="text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary">Global Water Intel</p>
                                  </div>
                                </a>
                              </div>
                            </div>
                            <div className="col-md-4 text-center my-2">
                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="http://www.smartwww.in/">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/express.jpg" />
                                  <div className="text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary">Smart Water & Waste World</p>
                                  </div>
                                </a>
                              </div>
                            </div>
                            <div className="col-md-4 text-center my-2">
                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="http://www.eawater.com">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/eaw.jpg" />
                                  <div className="text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary">Everything About Water</p>
                                  </div>
                                </a>
                              </div>
                            </div>
                            <div className="col-md-4 text-center my-2">
                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="https://www.thewaterdigest.com/">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/WaterDigest-Logo.png" />
                                  <div className="text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary">Water Digest</p>
                                  </div>
                                </a>
                              </div>
                            </div>
                            <div className="col-md-4 text-center my-2">
                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="http://www.thesmetimes.com/">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/sme-times.jpg" />
                                  <div className="text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary">The SME Times</p>
                                  </div>
                                </a>
                              </div>
                            </div>
                            <div className="col-md-4 text-center my-2">
                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="https://www.britishwater.co.uk/">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/BW-New.jpg" />
                                  <div className="text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary">British Water</p>
                                  </div>
                                </a>
                              </div>
                            </div>
                            <div className="col-md-4 text-center my-2">
                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="https://thewaternetwork.com/">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/logo-water-network.jpg" />
                                  <div className="text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary"> The Water Network</p>
                                  </div>
                                </a>
                              </div>
                            </div>
                            <div className="col-md-4 text-center my-2">
                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="http://www.b2bpurchase.com/">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/b2b-logo.jpg" />
                                  <div className="text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary">B2B Purchase</p>
                                  </div>
                                </a>
                              </div>
                            </div>


                          </div>

                          <h3 className="text-primary text-center">Travel Partner</h3>
                          <div className="row m-2">
                            <div className="col-md-4 text-center my-2">

                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="http://vueindiatours.com/">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/vue-india.jpg" />
                                  <div className=" text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary">Vue India Tours</p>
                                  </div>
                                </a>
                              </div>
                            </div>

                          </div>


                        </div>
                        <hr />
                      </div>
                    </li>

                    <li>
                      <a data-toggle="collapse" href="#accordion-list-3" className="collapsed"><span>2018</span> Sponsors / Supporters <i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i></a>
                      <div id="accordion-list-3" className="collapse" data-parent=".accordion-list">
                        <div className="d-block">
                          <h3 className="text-primary text-center">Clearance by </h3>
                          <div className="row m-2">
                            <div className="col-md-4 text-center my-2">

                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="http://mha.nic.in/">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/gov-logo.jpg" />
                                  <div className=" text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary">Ministry of Home Affairs<br /> Government of India</p>
                                  </div>
                                </a>
                              </div>
                            </div>

                            <div className="col-md-4 text-center my-2">
                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="http://www.mea.gov.in/">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/gov-logo.jpg" />
                                  <div className="text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary">Ministry of External Affairs<br />Government of India</p>
                                  </div>
                                </a>
                              </div>
                            </div>
                            <div className="col-md-8 text-center my-2">
                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="http://www.wrmin.nic.in/">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/gov-logo.jpg" />
                                  <div className="text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary">Ministry of Water Resources,<br />River Development & Ganga Rejuvenation,<br />Government of India</p>
                                  </div>
                                </a>
                              </div>
                            </div>
                          </div>

                          <hr />
                          <h3 className="text-primary text-center">Sponsored by </h3>
                          <div className="row m-2">
                            <div className="col-md-4 text-center my-2">

                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="http://www.parsan.biz/">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/parsan.jpg" />
                                  <div className=" text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary">PARSAN Overseas Pvt. Ltd.</p>
                                  </div>
                                </a>
                              </div>
                            </div>


                            <div className="col-md-4 text-center my-2">

                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="https://www.nabard.org/default.aspx">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/logo-nabard.jpg" />
                                  <div className="text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary">NABARD</p>
                                  </div>
                                </a>
                              </div>
                            </div>



                          </div>

                          <hr />
                          <h3 className="text-primary text-center">Associates </h3>
                          <div className="row m-2">






                            <div className="col-md-4 text-center my-2">
                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="http://www.cbip.org/">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/cbip.jpg" />
                                  <div className="text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary">Central Board of<br /> Irrigation and Power</p>
                                  </div>
                                </a>
                              </div>
                            </div>

                            <div className="col-md-4 text-center my-2">
                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="http://www.cidc.in/">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/cidc.jpg" />
                                  <div className="text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary">Construction Industry<br />Development Council</p>
                                  </div>
                                </a>
                              </div>
                            </div>


                            <div className="col-md-4 text-center my-2">
                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="http://ee-foundation.org/">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/eef.jpg" />
                                  <div className="text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary"> Energy And Environment<br />Foundation</p>
                                  </div>
                                </a>
                              </div>
                            </div>
                            <div className="col-md-4 text-center my-2">
                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="http://www.icid.org/">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/icid.png" />
                                  <div className="text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary">International Commission<br />on Irrigation and Drainage</p>
                                  </div>
                                </a>
                              </div>
                            </div>

                          </div>

                          <h3 className="text-primary text-center">Media Support by</h3>
                          <div className="row m-2">
                            <div className="col-md-4 text-center my-2">

                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="https://asianngo.org/">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/AsianNGO.jpg" />
                                  <div className=" text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary">Asian NGO</p>
                                  </div>
                                </a>
                              </div>
                            </div>

                            <div className="col-md-4 text-center my-2">
                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="https://www.globalwaterintel.com/">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/gwi.jpg" />
                                  <div className="text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary">Global Water Intel</p>
                                  </div>
                                </a>
                              </div>
                            </div>
                            <div className="col-md-4 text-center my-2">
                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="http://www.smartwww.in/">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/express.jpg" />
                                  <div className="text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary">Smart Water & Waste World</p>
                                  </div>
                                </a>
                              </div>
                            </div>

                            <div className="col-md-4 text-center my-2">
                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="http://www.b2bpurchase.com/">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/b2b-logo.jpg" />
                                  <div className="text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary">B2B Purchase</p>
                                  </div>
                                </a>
                              </div>
                            </div>
                            <div className="col-md-4 text-center my-2">
                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="http://www.eawater.com">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/eaw.jpg" />
                                  <div className="text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary">Everything About Water</p>
                                  </div>
                                </a>
                              </div>
                            </div>



                            <div className="col-md-4 text-center my-2">
                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="https://thewaternetwork.com/">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/logo-water-network.jpg" />
                                  <div className="text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary"> The Water Network</p>
                                  </div>
                                </a>
                              </div>
                            </div>
                            <div className="col-md-4 text-center my-2">
                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="http://www.scorp-media.com/">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/linfrastucture.jpg" />
                                  <div className="text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary">Infrastucture Line</p>
                                  </div>
                                </a>
                              </div>
                            </div>



                          </div>

                        </div>
                        <hr />


                      </div>
                    </li>

                    <li>
                      <a data-toggle="collapse" href="#accordion-list-4" className="collapsed"><span>2017</span> Sponsors / Supporters <i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i></a>
                      <div id="accordion-list-4" className="collapse" data-parent=".accordion-list">
                        <div className="d-block">
                          <h3 className="text-primary text-center">Clearance by </h3>
                          <div className="row m-2">
                            <div className="col-md-4 text-center my-2">
                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="http://www.mea.gov.in/">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/gov-logo.jpg" />
                                  <div className="text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary">Ministry of External Affairs<br />Government of India</p>
                                  </div>
                                </a>
                              </div>
                            </div>
                            <div className="col-md-4 text-center my-2">

                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="http://mha.nic.in/">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/gov-logo.jpg" />
                                  <div className=" text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary">Ministry of Home Affairs<br /> Government of India</p>
                                  </div>
                                </a>
                              </div>
                            </div>


                            <div className="col-md-8 text-center my-2">
                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="http://www.wrmin.nic.in/">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/gov-logo.jpg" />
                                  <div className="text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary">Ministry of Water Resources,<br />River Development & Ganga Rejuvenation,<br />Government of India</p>
                                  </div>
                                </a>
                              </div>
                            </div>
                          </div>

                          <hr />
                          <h3 className="text-primary text-center">Sponsored by </h3>
                          <div className="row m-2">
                            <div className="col-md-4 text-center my-2">
                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="http://www.parsan.biz/">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/parsan.jpg" />
                                  <div className=" text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary">PARSAN Overseas Pvt. Ltd.</p>
                                  </div>
                                </a>
                              </div>
                            </div>
                            <div className="col-md-4 text-center my-2">
                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="https://www.fraenkische.com/en/">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/frank.jpg" />
                                  <div className=" text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary">Frankische</p>
                                  </div>
                                </a>
                              </div>
                            </div>

                            <div className="col-md-4 text-center my-2">

                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="https://www.nabard.org/default.aspx">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/logo-nabard.jpg" />
                                  <div className="text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary">NABARD</p>
                                  </div>
                                </a>
                              </div>
                            </div>
                            <div className="col-md-4 text-center my-2">
                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="http://www.serb.gov.in/home.php">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/india-serb.png" />
                                  <div className=" text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary"> India Serb</p>
                                  </div>
                                </a>
                              </div>
                            </div>



                          </div>


                          <hr />
                          <h3 className="text-primary text-center">Industry Support by </h3>
                          <div className="row m-2">
                            <div className="col-md-4 text-center my-2">
                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="http://www.ambrogeo.eu/en">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/ambergeo-logo.jpg" />
                                  <div className=" text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary">Ambrogeo</p>
                                  </div>
                                </a>
                              </div>
                            </div>
                            <div className="col-md-4 text-center my-2">
                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="http://www.sarvajal.com/">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/piramal-logo.jpg" />
                                  <div className=" text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary">Piramal Water Private Limited</p>
                                  </div>
                                </a>
                              </div>
                            </div>

                            <div className="col-md-4 text-center my-2">

                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="http://www.maccaferri.com/in/">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/macc.jpg" />
                                  <div className="text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary">Maccaferri</p>
                                  </div>
                                </a>
                              </div>
                            </div>




                          </div>



                          <hr />
                          <h3 className="text-primary text-center">Associates </h3>
                          <div className="row m-2">

                            <div className="col-md-4 text-center my-2">
                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="http://www.awwa.org/">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/awwindia.png" />
                                  <div className="text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary">American Water<br />Works Association (AWWA)</p>
                                  </div>
                                </a>
                              </div>
                            </div>

                            <div className="col-md-4 text-center my-2">
                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="http://www.ilacc.com/">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/ilacc.png" />
                                  <div className="text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary">Indo Latin American<br />Chamber of Commerce</p>
                                  </div>
                                </a>
                              </div>
                            </div>


                            <div className="col-md-4 text-center my-2">
                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="http://www.ppa.pt/">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/pwp.jpg" />
                                  <div className="text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary">Portuguese Water<br />Partnership</p>
                                  </div>
                                </a>
                              </div>
                            </div>


                          </div>

                          <h3 className="text-primary text-center">Media Support by</h3>
                          <div className="row m-2">
                            <div className="col-md-4 text-center my-2">

                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="http://www.exhibitionsindiagroup.com/">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/logo3.jpg" />
                                  <div className=" text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary">Exhibitions India Group</p>
                                  </div>
                                </a>
                              </div>
                            </div>

                            <div className="col-md-4 text-center my-2">
                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="https://www.globalwaterintel.com/">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/gwi.jpg" />
                                  <div className="text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary">Global Water Intel</p>
                                  </div>
                                </a>
                              </div>
                            </div>
                            <div className="col-md-4 text-center my-2">
                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="https://thewaternetwork.com/">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/logo-water-network.jpg" />
                                  <div className="text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary"> The Water Network</p>
                                  </div>
                                </a>
                              </div>
                            </div>
                            <div className="col-md-4 text-center my-2">
                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="http://www.janhitfoundation.in/">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/jahit.jpg" />
                                  <div className="text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary">Janhit Foundation</p>
                                  </div>
                                </a>
                              </div>
                            </div>

                            <div className="col-md-4 text-center my-2">
                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="http://lankajalani.org/">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/jalanilogo2.jpg" />
                                  <div className="text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary">Sri Lanka Water Partnership</p>
                                  </div>
                                </a>
                              </div>
                            </div>

                            <div className="col-md-4 text-center my-2">
                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="http://www.eindiabusiness.com/">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/ebusiness.jpg" />
                                  <div className="text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary">eIndiabusiness</p>
                                  </div>
                                </a>
                              </div>
                            </div>

                            <div className="col-md-4 text-center my-2">
                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="http://www.tradeshowalerts.com/">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/tradeshow.jpg" />
                                  <div className="text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary">Trade Show Alerts</p>
                                  </div>
                                </a>
                              </div>
                            </div>

                            <div className="col-md-4 text-center my-2">
                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="http://constructionmirror.com/">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/cons-mirror.jpg" />
                                  <div className="text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary">Construction Mirror</p>
                                  </div>
                                </a>
                              </div>
                            </div>

                            <div className="col-md-4 text-center my-2">
                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="http://www.electricalmirror.net/">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/elec-mirror.jpg" />
                                  <div className="text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary">Electrical Mirror</p>
                                  </div>
                                </a>
                              </div>
                            </div>

                            <div className="col-md-4 text-center my-2">
                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="http://www.aiaiindia.com/">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/aia-2017.png" />
                                  <div className="text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary">AIA India </p>
                                  </div>
                                </a>
                              </div>
                            </div>
                            <div className="col-md-4 text-center my-2">
                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="http://www.eawater.com">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/eaw.jpg" />
                                  <div className="text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary">Everything About Water</p>
                                  </div>
                                </a>
                              </div>
                            </div>








                          </div>

                        </div>
                        <hr />

                      </div>
                    </li>

                    <li>
                      <a data-toggle="collapse" href="#accordion-list-4" className="collapsed"><span>2016</span> Sponsors / Supporters <i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i></a>
                      <div id="accordion-list-4" className="collapse" data-parent=".accordion-list">
                        <div className="d-block">
                          <h3 className="text-primary text-center">Clearance by </h3>
                          <div className="row m-2">
                            <div className="col-md-4 text-center my-2">
                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="http://www.mea.gov.in/">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/gov-logo.jpg" />
                                  <div className="text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary">Ministry of External Affairs<br />Government of India</p>
                                  </div>
                                </a>
                              </div>
                            </div>
                            <div className="col-md-4 text-center my-2">

                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="http://mha.nic.in/">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/gov-logo.jpg" />
                                  <div className=" text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary">Ministry of Home Affairs<br /> Government of India</p>
                                  </div>
                                </a>
                              </div>
                            </div>


                            <div className="col-md-8 text-center my-2">
                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="http://www.wrmin.nic.in/">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/gov-logo.jpg" />
                                  <div className="text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary">Ministry of Water Resources,<br />River Development & Ganga Rejuvenation,<br />Government of India</p>
                                  </div>
                                </a>
                              </div>
                            </div>
                          </div>

                          <hr />
                          <h3 className="text-primary text-center">Sponsored by </h3>
                          <div className="row m-2">
                            <div className="col-md-4 text-center my-2">
                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="http://www.parsan.biz/">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/parsan.jpg" />
                                  <div className=" text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary">PARSAN Overseas Pvt. Ltd.</p>
                                  </div>
                                </a>
                              </div>
                            </div>
                            <div className="col-md-4 text-center my-2">
                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="https://www.fraenkische.com/en/">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/frank.jpg" />
                                  <div className=" text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary">Frankische</p>
                                  </div>
                                </a>
                              </div>
                            </div>

                            <div className="col-md-4 text-center my-2">

                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="http://www.csirhrdg.res.in/">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/csir-logo.png" />
                                  <div className="text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary">Council of Scientific & Industrial Research</p>
                                  </div>
                                </a>
                              </div>
                            </div>
                            <div className="col-md-4 text-center my-2">
                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="http://dod.nic.in/">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/gov-logo.jpg" />
                                  <div className=" text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary">Ministry of Earth Sciences<br />Government of India</p>
                                  </div>
                                </a>
                              </div>
                            </div>



                          </div>


                          <hr />
                          <h3 className="text-primary text-center">Industry Support by </h3>
                          <div className="row m-2">
                            <div className="col-md-4 text-center my-2">
                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="http://www.maccaferri.com/in/">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/macc.jpg" />
                                  <div className="text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary">Maccaferri</p>
                                  </div>
                                </a>
                              </div>
                            </div>
                            <div className="col-md-4 text-center my-2">
                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="http://enzen.com/IN/Home">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/enzen-logo.png" />
                                  <div className=" text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary">Enzen Global Solutions Pvt. Ltd</p>
                                  </div>
                                </a>
                              </div>
                            </div>
                            <div className="col-md-4 text-center my-2">
                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="http://www.tethr.it/laravel/">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/tetherit-logo.jpg" />
                                  <div className=" text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary">Tether Box Technologies</p>
                                  </div>
                                </a>
                              </div>
                            </div>
                            <div className="col-md-4 text-center my-2">
                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="http://www.sarvajal.com/">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/piramal-logo.jpg" />
                                  <div className=" text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary">Piramal Water Private Limited</p>
                                  </div>
                                </a>
                              </div>
                            </div>
                            <div className="col-md-4 text-center my-2">
                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="http://www.cleanwaterproblem.com/">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/clean-water-logo.jpg" />
                                  <div className=" text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary">Clean Water Generator Pvt. Ltd</p>
                                  </div>
                                </a>
                              </div>
                            </div>
                            <div className="col-md-4 text-center my-2">
                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="http://www.ambrogeo.eu/en">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/ambergeo-logo.jpg" />
                                  <div className=" text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary">Ambrogeo</p>
                                  </div>
                                </a>
                              </div>
                            </div>


                          </div>



                          <hr />
                          <h3 className="text-primary text-center">Associates </h3>
                          <div className="row m-2">



                            <div className="col-md-4 text-center my-2">
                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="http://www.ilacc.com/">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/ilacc.png" />
                                  <div className="text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary">Indo Latin American<br />Chamber of Commerce</p>
                                  </div>
                                </a>
                              </div>
                            </div>


                            <div className="col-md-4 text-center my-2">
                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="http://www.ppa.pt/">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/pwp.jpg" />
                                  <div className="text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary">Portuguese Water<br />Partnership</p>
                                  </div>
                                </a>
                              </div>
                            </div>


                          </div>

                          <h3 className="text-primary text-center">Media Support by</h3>
                          <div className="row m-2">
                            <div className="col-md-4 text-center my-2">

                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="http://www.exhibitionsindiagroup.com/">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/logo3.jpg" />
                                  <div className=" text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary">Exhibitions India Group</p>
                                  </div>
                                </a>
                              </div>
                            </div>

                            <div className="col-md-4 text-center my-2">
                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="https://thewaternetwork.com/">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/logo-water-network.jpg" />
                                  <div className="text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary"> The Water Network</p>
                                  </div>
                                </a>
                              </div>
                            </div>



                            <div className="col-md-4 text-center my-2">
                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="http://www.janhitfoundation.in/">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/jahit.jpg" />
                                  <div className="text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary">Janhit Foundation</p>
                                  </div>
                                </a>
                              </div>
                            </div>

                            <div className="col-md-4 text-center my-2">
                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="http://www.aiaiindia.com/">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/aiai-logo.jpg" />
                                  <div className="text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary">All India Association of Industries</p>
                                  </div>
                                </a>
                              </div>
                            </div>

                            <div className="col-md-4 text-center my-2">
                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="http://lankajalani.org/">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/jalanilogo2.jpg" />
                                  <div className="text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary">Sri Lanka Water Partnership</p>
                                  </div>
                                </a>
                              </div>
                            </div>

                            <div className="col-md-4 text-center my-2">
                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="http://www.nswai.com/">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/nswai-hd.jpg" />
                                  <div className="text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary">National Solid Waste Association</p>
                                  </div>
                                </a>
                              </div>
                            </div>
                            <div className="col-md-4 text-center my-2">
                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="http://ceai.org.in/">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/ceal-logo.jpg" />
                                  <div className="text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary">Consulting Engineers Association of India</p>
                                  </div>
                                </a>
                              </div>
                            </div>

                            <div className="col-md-4 text-center my-2">
                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="http://www.awwa.org/">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/awwindia.png" />
                                  <div className="text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary">American Water<br />Works Association (AWWA)</p>
                                  </div>
                                </a>
                              </div>
                            </div>
                            <div className="col-md-4 text-center my-2">
                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="http://ipheindia.org/">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/IPHE-LOGO.jpg" />
                                  <div className="text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary">Institution of Public Health<br />Engineers, Delhi Regional Centre</p>
                                  </div>
                                </a>
                              </div>
                            </div>

                            <div className="col-md-4 text-center my-2">
                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="http://www.reinvent-life.com/">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/reinvent.png" />
                                  <div className="text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary">Reinvent Life</p>
                                  </div>
                                </a>
                              </div>
                            </div>

                            <div className="col-md-4 text-center my-2">
                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="http://www.worldbuild-india.com/">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/worldbuild.jpg" />
                                  <div className="text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary">World Build India</p>
                                  </div>
                                </a>
                              </div>
                            </div>




                            <div className="col-md-4 text-center my-2">
                              <div className="border p-0">
                                <a className="p-0" target="_blank" rel="noreferrer" href="https://www.globalwaterintel.com/">
                                  <img alt="" className="m-2" style={{ height: "90px", width: "auto" }} src="assets/images/partners/gwi.jpg" />
                                  <div className="text-white bg-primary w-100">
                                    <p className="p-1 text-white bg-primary">Global Water Intel</p>
                                  </div>
                                </a>
                              </div>
                            </div>


                          </div>

                        </div>
                        <hr />

                      </div>
                    </li>


                  </ul>
                </div>

              </div>

              <div className="col-lg-5 align-items-stretch order-1 order-lg-2 img" style={{ backgroundImage: "assets/images/why-us.png" }} data-aos="zoom-in" data-aos-delay="150">&nbsp;</div>
            </div>
          </section>
        </div>
      </div>
    </InnerLayout>
  );
}

export default Partners;
